<template>
  <div>
    <CCard class="mt-3">
      <CCardBody>
        <div class="d-flex flex-wrap">
          <TInputRadio
            :list="filter_options"
            :value.sync="temp.filter_radio"
            @update:value="triggerSearch"
            label="Option"
            custom
          />
        </div>
      </CCardBody>
    </CCard>

    <TTableAdvance
      :items="list"
      :fields="fields"
      @click-clear-filter="clearFilter"
      store="warehouse.box_containers"
      resource=""
    >
      <template #_="{ index }">
        <td>
          <TMessage :content="index + 1" alignment="center" noTranslate />
        </td>
      </template>
      <template #id="{ item }">
        <td>
          <TMessage :content="item.id" noTranslate />
        </td>
      </template>
      <template #container="{ item }">
        <td>
          <TLink
            :content="item.container ? item.container.name : item.container_id"
            :to="lodash.getReferenceLink('invoice', item.container_id)"
          />
        </td>
      </template>
      <template #quantity_in_lading_bill="{ item }">
        <td>
          <TMessageNumber :value="item.quantity_in_lading_bill" /></td
      ></template>
      <template #quantity_in_picker="{ item }">
        <td>
          <TMessageNumber :value="item.quantity_in_picker" /></td
      ></template>
      <template #quantity_out_picker="{ item }">
        <td>
          <TMessageNumber :value="item.quantity_out_picker" />
        </td>
      </template>

      <template #box_id-filter>
        <TInputText
          placeholder="SKU Id"
          :value.sync="filter.box_id"
          @update:value="triggerSearch"
        />
      </template>
      <template #container-filter>
        <SSelectInvoice
          prependAll
          :value.sync="filter.container_id"
          @change="triggerSearch"
          class="flex-grow-1"
        />
      </template>
    </TTableAdvance>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      temp: {
        filter_radio: "available_in_picker",
      },
      filter: {
        available_in_picker: 1,
      },
      filter_options: [
        {
          value: "available_in_picker",
          label: this.$t("Box available in picker"),
        },
        {
          value: "available_out_picker",
          label: this.$t("Box available out picker"),
        },
        {
          value: "overload_out_picker",
          label: this.$t("Box overload out picker"),
        },
      ],
      fields: [
        { key: "_", _style: "width: 20px" },
        {
          key: "box_id",
          label: "SKU Id",
          _style: "width: 140px; min-width: 140px",
        },
        {
          key: "container",
          label: "Invoice",
          _classes: "",
          _style: "width: 140px; min-width: 140px",
        },
        {
          key: "quantity_in_lading_bill",
          label: "Quantity in lading bill",
          _style: "width: 140px; min-width: 140px",
          sorter: true,
        },
        {
          key: "quantity_in_picker",
          label: "Quantity in cont",
          _classes: "",
          _style: "width: 140px; min-width: 140px",
          sorter: true,
        },
        {
          key: "quantity_out_picker",
          label: "Quantity out cont",
          _classes: "",
          _style: "width: 140px; min-width: 140px",
          sorter: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      loading: "warehouse.box_containers.fetching",
      list: "warehouse.box_containers.list",
    }),
  },
  created() {
    this.triggerSearch();
  },
  methods: {
    triggerSearch() {
      let params = {};

      this.filter = {
        ...this.filter,
        available_in_picker: 0,
        available_out_picker: 0,
        overload_out_picker: 0,
        [this.temp.filter_radio]: 1,
      };

      Object.entries(this.filter).forEach(([k, v]) => {
        if (v) params[`filter[${k}]`] = v;
      });

      this.$store.dispatch("warehouse.box_containers.apply-query", params);
    },
    clearFilter() {
      this.filter = { available_in_picker: 1 };
      this.temp = { filter_radio: "available_in_picker" };

      this.triggerSearch();
    },
    getQuantityByFilter(item) {
      let rest = this.lodash.omit(this.filter, ["container_id"]);
      let current_radio_api_filter_key = Object.entries(rest).find(
        (i) => i[1]
      )?.[0];

      if (current_radio_api_filter_key === "available_in_picker")
        return item.quantity_in_lading_bill - item.quantity_in_picker;

      if (current_radio_api_filter_key === "available_out_picker")
        return item.quantity_in_picker - item.quantity_out_picker;

      // overload_out_picker
      return item.quantity_out_picker - item.quantity_in_picker;
    },
  },
};
</script>
